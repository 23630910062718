import CryptoJS from "crypto-js";
export default {
  decrypt(str) {
    const dec = CryptoJS.AES.decrypt(
      str,
      CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPT_KEY),
      {
        iv: CryptoJS.enc.Utf8.parse(process.env.REACT_APP_IV_KEY), // parse the IV
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC,
      }
    );
    return dec.toString(CryptoJS.enc.Utf8);
  },
  encrypt(str) {
    const enc = CryptoJS.AES.encrypt(
      str,
      CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPT_KEY),
      {
        iv: CryptoJS.enc.Utf8.parse(process.env.REACT_APP_IV_KEY), // parse the IV
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC,
      }
    );
    return enc.toString();
  },
};
