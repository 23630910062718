import axios from "axios";
import CryptoJS from "crypto-js";
import helper from "./helper";
const http = axios.create();
http.defaults.baseURL = process.env.REACT_APP_API_URL;
http.interceptors.request.use(
  (config) => {
    const date = new Date().getTime();
    const str = `xmen:${date}`;
    const signed = CryptoJS.HmacSHA256(
      str,
      process.env.REACT_APP_ENCRYPT_KEY
    ).toString(CryptoJS.enc.Hex);
    config.headers["x-encrypt"] = signed;
    config.headers["x-time"] = date;

    const isBodyValid = config.data;
    const noEncrypt = config.headers.noEncrypt;
    const isEncrypt = config.method !== "get" && !noEncrypt && isBodyValid;

    if (isEncrypt) {
      const enc = helper.encrypt(JSON.stringify(config.data));
      config.data = { data: enc };
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
http.defaults.headers.post["Content-Type"] = "application/json";
http.defaults.headers.post["Accept"] = "application/json";

export default http;
