import { SurveyElementBase } from "survey-react-ui";

class CustomRatingItem extends SurveyElementBase {
  renderElement() {
    const question = this.props.question;
    const item = this.props.item;

    const isDisplayMode = this.isDisplayMode;
    const handleOnClick = this.props.handleOnClick;
    const handleOnMouseDown = this.props.handleOnMouseDown;
    const index = this.props.index;

    return (
      <label
        onMouseDown={handleOnMouseDown}
        className={`${question.getItemClass(item.itemValue)} outer-label`}
        onMouseOver={(e) => question.onItemMouseIn(item)}
        onMouseOut={(e) => question.onItemMouseOut(item)}
      >
        <input
          type="radio"
          className="sv-visuallyhidden"
          name={question.name}
          id={question.getInputId(index)}
          value={item.value}
          disabled={isDisplayMode}
          checked={question.value == item.value}
          onClick={handleOnClick}
          onChange={() => {}}
          aria-required={question.ariaRequired}
          aria-label={question.ariaLabel}
          aria-invalid={question.ariaInvalid}
          aria-describedby={question.ariaDescribedBy}
        />
        <label className={"caption"}>{item.text}</label>
      </label>
    );
  }
}

export default CustomRatingItem;
