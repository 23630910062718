import React from "react";
import ClearCache from "react-clear-cache";
import Main from "./apps/main";
import BlankCacheComponent from "./components/BlankCacheComponent";
import "./index.css";
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    return (
      <ClearCache>
        {({ isLatestVersion, emptyCacheStorage }) =>
          !isLatestVersion ? (
            <BlankCacheComponent
              emptyCacheStorage={emptyCacheStorage}
              isLatestVersion={isLatestVersion}
            />
          ) : (
            <Main
              isLatestVersion={isLatestVersion}
              emptyCacheStorage={emptyCacheStorage}
            />
          )
        }
      </ClearCache>
    );
  }
}
export default App;
