import { SurveyElementBase, SvgIcon } from "survey-react-ui";

class CustomChoiceItem extends SurveyElementBase {
  renderElement() {
    const question = this.props.question;
    const item = this.props.item;

    const isDisplayMode = this.isDisplayMode;
    const handleOnClick = this.props.handleOnClick;
    const handleOnMouseDown = this.props.handleOnMouseDown;
    const index = this.props.index;

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: "#fff",
            justifyContent: "center",
            marginBottom: 10,
            gap: 2,
          }}
        >
          {(item.value === 6
            ? [0, 1, 2, 3, 4, 5, 6]
            : item.value === 8
            ? [7, 8]
            : [9, 10]
          ).map((v) => (
            <div
              style={{
                background:
                  item.value === 6
                    ? "red"
                    : item.value === 8
                    ? "gray"
                    : "green",
                padding: "1px 3px",
                fontSize: 10,
                borderRadius: 8,
              }}
            >
              {v}
            </div>
          ))}
        </div>
        <label
          onMouseDown={handleOnMouseDown}
          className={`${question.getItemClass(item.itemValue)} outer-label-${
            item.value === 6
              ? "icon-red"
              : item.value === 8
              ? "icon-gray"
              : "icon-green"
          }`}
          onMouseOver={(e) => question.onItemMouseIn(item)}
          onMouseOut={(e) => question.onItemMouseOut(item)}
        >
          <input
            type="radio"
            className="sv-visuallyhidden"
            name={question.name}
            id={question.getInputId(index)}
            value={item.value}
            disabled={isDisplayMode}
            checked={question.value == item.value}
            onClick={handleOnClick}
            onChange={() => {}}
            aria-required={question.ariaRequired}
            aria-label={question.ariaLabel}
            aria-invalid={question.ariaInvalid}
            aria-describedby={question.ariaDescribedBy}
          />
          <SvgIcon
            className={
              item.value === 6
                ? "icon-red"
                : item.value === 8
                ? "icon-gray"
                : "icon-green"
            }
            size={"auto"}
            iconName={
              item.value === 6
                ? "icon-not-good"
                : item.value === 8
                ? "icon-normal"
                : "icon-very-good"
            }
            title={item.text}
          ></SvgIcon>
        </label>
        <label className={"caption"}>{item.text}</label>
      </div>
    );
  }
}

export default CustomChoiceItem;
