import Button from "antd/es/button";
import React from "react";
export default function DetailSurveyComponent({ detail, startSurvey, cabang }) {
  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <img
          src={"/logo.png"}
          alt="Logo BRI"
          style={{ width: 200, objectFit: "contain", marginBottom: 10 }}
        />
        <div
          style={{
            fontSize: 17,
            color: "#000",
            fontWeight: "bold",
          }}
        >
          Survey Kepuasan Nasabah BRI 2024
        </div>
      </div>
      <div
        style={{
          background: "#fff",
          borderRadius: 8,
          padding: 20,
          marginTop: 20,
        }}
      >
        <div
          style={{
            fontSize: 17,
            fontWeight: "bold",
            marginBottom: 20,
          }}
        >
          Terima kasih atas perhatian Bapak/Ibu untuk berpartisipasi dalam
          Survey Kepuasan Nasabah terhadap Kantor Cabang BRI
        </div>

        <div
          style={{
            fontSize: 17,
            paddingBottom: 20,
            lineHeight: 1.5,
          }}
        >
          Dalam rangka meningkatkan layanan Kantor Cabang dan memberikan
          pengalaman terbaik bagi nasabah BRI, kami membutuhkan saran & masukan
          Anda berdasarkan pengalaman Anda di {cabang} dalam 1 bulan terakhir.
          Survey ini hanya membutuhkan waktu 5 menit.
          <br />
          <br /> Dalam survey ini BRI tidak pernah meminta data rahasia
          Bapak/Ibu dalam bentuk apapun. Hasil survey hanya akan digunakan untuk
          penelitian & evaluasi layanan Kantor Cabang BRI. <br /> <br />{" "}
          Partisipasi Anda sangat berarti bagi kami agar kami dapat memberikan
          pelayanan yang terbaik bagi nasabah tercinta. Terima kasih atas
          perhatian dan partisipasi yang diberikan.
          <br />
          <br /> PT. Bank Rakyat Indonesia (Persero), Tbk.
        </div>
      </div>
      <Button
        type="primary"
        block
        style={{ marginTop: 20, marginBottom: 20 }}
        onClick={() => startSurvey("")}
      >
        Saya Bersedia Mengikuti Survey
      </Button>
    </div>
  );
}
